<template>
  <div class="login">
    <div class="title">登录</div>
    <div class="longi-form">
      <div class="input-list account">
        <input type="text" placeholder="请输入用户账号" v-model="account">
      </div>
      <div class="input-list pasd">
        <input type="password" placeholder="请输入登录密码" v-model="password">
      </div>
      <div class="submit" @click="login">登录</div>
    </div>
  </div>
</template>
<script>
document.title ='连物界'
import { showToast } from 'vant';
import { defineComponent } from 'vue'
import { emailLogin } from "@/api/yijifen";
export default defineComponent({
    data() {
      return {
        account:'',
        password:''
      }
    },
		created() {
			const isWx = this.isWinXinBrowser();
			if(localStorage.getItem('ticketToken')){
        // this.$router.replace({ name:'yijifenVerification'})
        // window.location.href = 'https://test-tot.wangyuanju.net/yijifen/verification'
        let name = '/yijifen/verification'
        window.location.replace(name)
      }
		},
    methods: {
      isWinXinBrowser() {
          //是否是微信浏览器
          if (/(micromessenger)/i.test(navigator.userAgent)) {
              // //是否电脑微信或者微信开发者工具
              // if (/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)) {
              //     alert('电脑微信或者微信开发者工具')
              // } else {
              //     //手机微信打开的浏览器
              //     alert('手机微信')
              // }
              return true;
          } else {
              // alert('其他浏览器')
              return false;
          }
      },
      login() {
        let account = [
          'lcx1@163.com',
          'lcx2@163.com',
          'lcx3@163.com'
        ]
        if(!this.account){
          showToast('请填写账号');
          return
        }
        if(!this.password){
          showToast('请填写密码');
          return
        }
        // if(!account.includes(this.account)){
        //   showToast('非核销账号');
        //   return
        // }
        emailLogin({
          email: this.account,
          password: this.password,
        }).then((res) => {
          console.log(res)
          if(res.code == 200){
            localStorage.setItem('ticketToken',res.result.token)
            localStorage.setItem('email',res.result.email)
            localStorage.setItem('nickName',res.result.nickName)
            // this.$router.push({ name:'yijifenVerification'})
            window.location.replace('https://test-tot.wangyuanju.net/yijifen/verification')
            let name = '/yijifen/verification'
             window.location.replace(name)
          }else{
            showToast(res.msg);
          }
        }).catch((error)=>{
          console.log(error)
          showToast(error.msg);
        });
      }
    },
})

</script>
<style lang="scss" scoped>
  .login{
    width: 100%;
    height: 100vh;
    background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/login-bg.png') no-repeat;
    background-size: cover;
  }
  .back{
    width: 12px;
    height: 20px;
    background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/back.png') no-repeat;
    background-size: contain;
  }
  .title{
    width: 105px;
    height: 50px;
    padding-top: 160px;
    margin-left: 10px;
    color: #A5FFFD;
    font-size: 28px;
  }
  .longi-form{
    width: 324px;
    height: 248px;
    background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/input-bg.png') no-repeat;
    background-size: 100% 100%;
    margin: 10px auto 0 auto;
    padding: 35px 0 0 12px;
    box-sizing: border-box;
    .input-list{
      width: 284px;
      height: 42px;
      border-radius: 6px;
      background: #182C33;
      margin-bottom: 20px;
      padding: 0 14px;
      box-sizing: border-box;
      input{
        width: 100%;
        height: 42px;
        color: #A5FFFD;
        font-size: 14px;
        background: transparent;
        border: none;
        outline: none;
      }
    }
    .submit{
      width: 284px;
      height: 46px;
      border-radius: 6px;
      background: #A5FFFD;
      color: #000;
      font-size: 14px;
      text-align: center;
      line-height: 46px;
      margin-top: 34px;
    }
  }
</style>
